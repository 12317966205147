import React from "react";
import styled from "styled-components";
import Gallery from "./Gallery";
import Row from "./Layout/Row";
import { useStaticQuery, graphql } from "gatsby";

const GalleryPropertiesSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 5.2rem));
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }
`;

const GalleryPropertiesSection = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allSanityPropertiesGallery {
        nodes {
          gallery {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            _key
          }
        }
      }
    }
  `);

  const galleryImages = data.allSanityPropertiesGallery.nodes[0].gallery;

  return (
    <GalleryPropertiesSectionStyles>
      <Row>
        <h2>Representative Photos</h2>
      </Row>

      <Gallery images={galleryImages} />
    </GalleryPropertiesSectionStyles>
  );
};

export default GalleryPropertiesSection;
