import React, { useState } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Row from "./Layout/Row";

const GalleryStyles = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 5.2rem));
  grid-auto-rows: 15rem;
  gap: 2rem 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .image {
    background: var(--color-light-gray);
    overflow: hidden;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .image1,
  .image5,
  .image8,
  .image12,
  .image15,
  .image19,
  .image22,
  .image26 {
    grid-column-end: span 4;
    grid-row-end: span 2;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
      grid-row-end: span 1;
      margin-bottom: 1em;
    }
  }
  .image2,
  .image3,
  .image4,
  .image6,
  .image7,
  .image9,
  .image10,
  .image11,
  .image13,
  .image14,
  .image16,
  .image17,
  .image18,
  .image20,
  .image21,
  .image23,
  .image24,
  .image25,
  .image27,
  .image28,
  .image29,
  .image30,
  .image31,
  .image32,
  .image33,
  .image34 {
    grid-column-end: span 4;
    grid-row-end: span 1;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
      grid-row-end: span 1;
      margin-bottom: 1em;
    }
  }
`;

const Gallery = ({ images }) => {
  const [visible, setVisible] = useState(7);

  const handleClick = () => {
    if (visible < images.length) {
      setVisible((prevValue) => prevValue + 7);
    }
  };

  return (
    <>
      <GalleryStyles>
        {images.slice(0, visible).map((image, i) => (
          <div key={image._key} className={`image image${i + 1}`}>
            <GatsbyImage
              image={image.asset.localFile.childImageSharp.gatsbyImageData}
              alt="Students"
            />
          </div>
        ))}
      </GalleryStyles>
      <Row>
        {visible < images.length && (
          <button className="btn btn-primary" onClick={handleClick}>
            Load More
          </button>
        )}
      </Row>
    </>
  );
};

export default Gallery;
