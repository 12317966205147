import React from "react";
import styled from "styled-components";
import GalleryPropertiesSection from "../components/GalleryPropertiesSection";
import Layout from "../components/Layout";
import MapPropertiesSection from "../components/MapPropertiesSection";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";

const PropertiesStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }
`;

const Properties = () => {
  return (
    <Layout>
      <Seo title="Properties" />
      <TopTitleBar title="Properties" />
      <PropertiesStyles>
        <MapPropertiesSection />
        <GalleryPropertiesSection />
      </PropertiesStyles>
    </Layout>
  );
};

export default Properties;
