import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import GoogleMaps from "./GoogleMaps";

const MapPropertiesSectionStyles = styled.section`
  position: relative;
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  background: var(--color-black);
  height: 38rem;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
    height: 26rem;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .map {
    position: relative;
    grid-column: 1 / span 14;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 8;
    }
  }
`;

const MapPropertiesSection = () => {
  const data = useStaticQuery(graphql`
    query mapPropertiesAndMapPoiQuery {
      allSanityProperties {
        nodes {
          offStreetParking
          property
          location {
            lat
            lng
          }
          _id
        }
      }
      allSanityPoi {
        nodes {
          poi
          poiImage {
            asset {
              url
            }
          }
          poiLocation {
            lat
            lng
          }
          _id
        }
      }
    }
  `);

  const properties = data.allSanityProperties.nodes;
  const pois = data.allSanityPoi.nodes;

  return (
    <MapPropertiesSectionStyles>
      <div className="map">
        <GoogleMaps properties={properties} pois={pois} zoomLevel={16} />
      </div>
    </MapPropertiesSectionStyles>
  );
};

export default MapPropertiesSection;
